.newsletter-input-footer:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px rgba(81, 120, 193, 0.898) inset;
    -webkit-text-fill-color: white !important;
  }

.autofill-input-global:-webkit-autofill{
    -webkit-text-fill-color: #1D1EA0 !important;
}

.newsletter-input-footer {
  height: 32px;
}
