@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './typography.css';
@import './slider/product-list-related.css';
@import './slider/product-bundle-list.css';
@import './slider/checkout-summary.css';
@import './utilities/wrapper.css';
@import './utilities/richtext.scss';
@import './utilities/background.css';
@import './footer/newsletter-input.css';
@import './amount-selection/amount-selection.css';
@import './storybloks/block-six.css';
@import './colors.css';
@import './search/search.css';

/* Disable blue box on mobile tap */
* {
  -webkit-tap-highlight-color: transparent;
}

html.focus-outline-hidden *:focus {
  outline: none;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-color: #1D1EA0
}
*:focus:not(:focus-visible) { outline: none; }

#launcher, .back-to-top {
  height: 48px !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px, rgb(0 0 0 / 12%) 0px 1px 18px;
  border-radius: 500px;
}

@media (min-width: 768px) {
  #launcher {
    height: 46px !important;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.rich-text p:empty{
  height: 1rem;
}

.rich-text a {
    text-decoration: underline;
}

.rich-text ol {
    list-style: decimal;
}

.rich-text ul {
    list-style: disc;
}

.rich-text ol, .rich-text ul, .rich-text menu {
  padding-left: 1rem;
}
