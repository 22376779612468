@font-face {
  font-family: "PPFraktionSans";
  src: url("/fonts/PPFraktionSans-Variable.ttf");
  font-weight: 100 1000;
	font-stretch: 25% 151%;
  font-display: swap;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'PPFraktionSans', sans-serif;
  @apply text-p-16;
}

[class*="text-h-"] {
  text-transform: uppercase;
  font-weight: 800;
}

[class*="text-p-"] {
  text-transform: none;
  font-weight: 390;
}

h1 {
  @apply uppercase font-extrabold text-h-40;
}

h2 {
  @apply uppercase font-extrabold text-h-32;
}

h3 {
  @apply uppercase font-extrabold text-h-24;
}

h4 {
  @apply uppercase font-extrabold text-h-20;
}

h5 {
  @apply uppercase font-extrabold text-h-16;
}

h6 {
  @apply uppercase font-extrabold text-h-14;
}
