:root {
  --accent-primary: #1D1EA0;
  --accent-pressed: #2728B2;
  --accent-hover: #2425C8;
  --accent-primary_02: #1D1EA033;
  --accent-primary_01: #1D1EA01A;
  --accent-primary_005: #1D1EA00D;

  --dark-background: rgba(39, 41, 55, 0.05);
  --dark-black: #000;
  --dark-primary: #272937;
  --dark-quaternary: rgba(39, 41, 55, 0.12);
  --dark-secondary: rgba(39, 41, 55, 0.75);
  --dark-tertiary: rgba(39, 41, 55, 0.35);
  --dark_05: rgba(39, 41, 55, 0.05);

  --light-white: #fff;
  --light-primary: rgba(255, 255, 255, 0.93);
  --light-secondary: rgba(255, 255, 255, 0.65);
  --light-tertiary: rgba(255, 255, 255, 0.35);
  --light-quaternary: rgba(255, 255, 255, 0.2);
  --light-background: rgba(255, 255, 255, 0.1);

  --special-danger: #FF3A30;
  --special-success: #48B527;
  --special-warn: #FFB649;
  --special-info: #3385FF;
  --special-boat: #00C8FF;
  --special-dog: #FF6A5B;
  --special-horse: #00DEB4;
}
