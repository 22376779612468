.amount-selection-outline {
    outline: none;
}

.amount-selection-outline:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.amount-selection-full-outline:focus-within {
    border-color: var(--accent-pressed);
}