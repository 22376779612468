.slick-slider.checkout-summary-slider {
  @apply relative -mx-1.5;
}

.slick-slider.checkout-summary-slider .slick-track {
  @apply flex;
}

.slick-slider.checkout-summary-slider .slick-list {
  @apply overflow-hidden;
}

.slick-slider.checkout-summary-slider .slick-arrow {
  @apply absolute right-0 translate-y-1/2 bottom-1/2;
}

.slick-slider.checkout-summary-slider .slick-arrow.slick-disabled {
  @apply opacity-20;
}

.slick-slider.checkout-summary-slider .slick-arrow.slick-next {
  @apply right-4;
}

.slick-slider.checkout-summary-slider .slick-arrow.slick-prev {
  @apply left-4;
}
