.slick-slider.product-list-related-slider {
  @apply relative grid grid-cols-2 overflow-y-visible;
}

.slick-slider.product-list-related-slider .slick-track {
  @apply flex overflow-y-visible pb-2;
}

.slick-slider.product-list-related-slider .slick-list {
  @apply col-span-2 overflow-visible z-10;
}

.slick-slider.product-list-related-slider .slick-arrow {
  @apply relative lg:absolute lg:right-0 lg:bottom-full lg:mb-6;
}

.slick-slider.product-list-related-slider .slick-arrow.slick-disabled {
  @apply opacity-20;
}

.slick-slider.product-list-related-slider .slick-arrow.slick-next {
  @apply self-start order-2 lg:right-4;
}

.slick-slider.product-list-related-slider .slick-arrow.slick-prev {
  @apply order-1 lg:mr-3 lg:left-auto lg:right-4 lg:-translate-x-full justify-self-end;
}
