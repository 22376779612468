.richTextBlock {
  word-break: break-word;
  hyphens: auto;
}


.richTextBlock > ol {
  list-style-type: decimal;
}

.richTextBlock > ol > li {
  @apply ml-6;
}

.richTextBlock > p {
  @apply mb-4;
}

.richTextBlock > h1 {
  @apply mb-4 text-h-32 md:text-h-40;
}

.richTextBlock > h2 {
  @apply mb-4 text-h-24 md:text-h-32;
}

.richTextBlock > h3 {
  @apply mb-4 text-h-20 md:text-h-24;
}

.richTextBlock > h4 {
  @apply mb-4;
}

.richTextBlock > h5 {
  @apply mb-4;
}

.richTextBlock > h6 {
  @apply mb-4;
}
