@layer utilities {
  .space-y-wrapper {
    @apply space-y-20;
  }

  .space-y-wrapper-small {
    @apply space-y-20;
  }

  .box-x-wrapper {
    @apply px-4;
  }

  .pr-wrapper {
    @apply pr-4;
  }

  .pl-wrapper {
    @apply pl-4;
  }

  .mt-wrapper {
    @apply mt-20;
  }

.mt-wrapper-small {
  @apply mt-20;
}

  @screen md {
    .box-x-wrapper {
      @apply px-[5%];
    }

    .pr-wrapper {
      @apply pr-[5%];
    }

    .pl-wrapper {
      @apply pl-[5%];
    }
  }

  @screen lg {
    .space-y-wrapper {
      @apply space-y-64;
    }

    .space-y-wrapper-small {
      @apply space-y-40;
    }

    .box-x-wrapper {
      @apply px-[5%] mx-auto max-w-7xl;
    }

    .box-x-wrapper-half {
      width: calc(100% / 2 + 100vw / 2);
    }

    .pr-wrapper {
      @apply pr-[5%];
    }

    .pl-wrapper {
      @apply pl-[5%];
    }

    .mt-wrapper {
      @apply mt-64;
    }

.mt-wrapper-small {
  @apply mt-40;
}
  }

  @screen xl {
    .space-y-wrapper {
      @apply space-y-64;
    }

    .space-y-wrapper-small {
      @apply space-y-40;
    }

    .box-x-wrapper {
      @apply px-[5%] mx-auto max-w-7xl;
      max-width: 104rem;
    }

    .box-x-wrapper-half {
      width: calc(100% / 2 + 100vw / 2);
    }

    .pr-wrapper {
      @apply pr-[5%];
    }

    .pl-wrapper {
      @apply pl-[5%];
    }

    .mt-wrapper {
      @apply mt-64;
    }
    
.mt-wrapper-small {
  @apply mt-40;
}
  }
}
